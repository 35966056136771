type Props = {
    title: string;
    subTitle?: string;
    image?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

function AnnouncementCard({ title, subTitle, image, onClick }: Props) {
    return (
        <a
          href="#"
          className="d-flex"
          onClick={onClick}
        >
            <div className="me-auto">
                <h5 className="font-600 pt-2">{title}</h5>
                <span className="color-highlight opacity-60">{subTitle}</span>
            </div>
            {image && (
                <div className="ms-4 mb-4">
                    <img src={image} className="rounded-sm" width="100" />
                </div>
            )}
        </a>
    );
}

export default AnnouncementCard;