import { useState } from "react";
import { logEvent } from "firebase/analytics";

import NavigationBar from "../../components/layout/NavigationBar";
import ScreenLayout from "../../components/layout/ScreenLayout";
import Header from "../../components/layout/Header";
import ContentMenu from "../../components/content-menu";
import LogEvent from "../../components/async/log-event";

import BlogCard from "./components/BlogCard";

import type { Post } from "../../types/posts";

import { analytics } from "../../firebase";

import { usePosts } from "../../hooks/use-posts";

import { formatData } from "../../utils/format-data";

function BlogScreen() {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [currentPost, setCurrentPost] = useState<Post | null>(null);

  const postsResponse = usePosts({
    params: {
      include: 'authors,tags',
      filter: 'tag:blog',
    },
    options: {
      staleTime: 30000,
      refetchOnWindowFocus: false,
    }
  });
  const posts = postsResponse.data?.posts || [];

  const handleOpenMenu = (post: Post) => {
    setIsMenuActive(true);
    setCurrentPost(post);

    logEvent(analytics, 'view_blog_content', {
      blog_title: post.title,
      blog_slug: post.slug,
      blog_id: post.id,
    });
  };

  const handleCloseMenu = () => {
    setIsMenuActive(false);
    setCurrentPost(null);
  }

  return (
    <ScreenLayout preloader={postsResponse.isLoading}>
      <div className="page-content">
        <Header pageTitle="İçerikler" />

        {posts?.map((post) => (
          <BlogCard
            key={post?.id}
            title={post.title}
            image={post.feature_image}
            // categories={post.tags?.map((tag) => ({ id: tag.id, title: tag.name }))}
            createdAt={post?.created_at || ''}
            onClick={() => handleOpenMenu(post)}
          />
        ))}
      </div>

      <ContentMenu
        title={currentPost?.title || ''}
        description={formatData(currentPost?.created_at || '')}
        htmlContent={currentPost?.html || ''}
        image={currentPost?.feature_image}
        isActive={isMenuActive}
        handleCloseMenu={handleCloseMenu}
      />

      <NavigationBar />

      <LogEvent eventName="blog_page_visit" />
    </ScreenLayout>
  );
}

export default BlogScreen;