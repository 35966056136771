// Componenets
import LogEvent from "../../components/async/log-event";
import ImageGallery from "../../components/image-gallery";
import Divider from "../../components/layout/Divider";
import Header from "../../components/layout/Header";
import NavigationBar from "../../components/layout/NavigationBar";
import ScreenLayout from "../../components/layout/ScreenLayout";

import { usePosts } from "../../hooks/use-posts";

// Module Components
import ProjectInfo from "./components/ProjectInfo";
import ProjectPartners from "./components/ProjectPartners";
import GalleryContent from "./components/GalleryContent";

const projectSchoolImages = [
    'images/gallery/project-school/e-1.jpg',
    'images/gallery/project-school/y-1.jpg',
    'images/gallery/project-school/e-2.jpg',
    'images/gallery/project-school/y-2.jpg',
    'images/gallery/project-school/e-3.jpg',
    'images/gallery/project-school/y-4.jpg',
    'images/gallery/project-school/e-4.jpg',
    'images/gallery/project-school/y-5.jpg',
    'images/gallery/project-school/e-5.jpg',
    'images/gallery/project-school/y-6.jpg',
    'images/gallery/project-school/e-6.jpg',
    'images/gallery/project-school/y-7.jpg',
    'images/gallery/project-school/e-7.jpg',
    'images/gallery/project-school/y-8.jpg',
    'images/gallery/project-school/y-9.jpg',
    'images/gallery/project-school/y-10.jpg',
];

const innovationFairImages = [
    'images/gallery/innovation-fair/1.jpg',
    'images/gallery/innovation-fair/2.jpg',
    'images/gallery/innovation-fair/3.jpg',
    'images/gallery/innovation-fair/4.jpg',
    'images/gallery/innovation-fair/5.jpg',
    'images/gallery/innovation-fair/6.jpg',
    'images/gallery/innovation-fair/7.jpg',
    'images/gallery/innovation-fair/8.jpg',
];

const ramiLibraryImages = [
    'images/gallery/rami-library/3.jpg',
    'images/gallery/rami-library/2.jpg',
    'images/gallery/rami-library/1.jpg',
    'images/gallery/rami-library/4.jpg',
    'images/gallery/rami-library/5.jpg',
    'images/gallery/rami-library/6.jpg',
    'images/gallery/rami-library/7.jpg',
    'images/gallery/rami-library/8.jpg',
    'images/gallery/rami-library/9.jpg',
    'images/gallery/rami-library/10.jpg',
];

function HomeScreen() {
    const postsResponse = usePosts({
        params: {
          include: 'authors,tags',
          filter: 'tag:ana-sayfa-slayti',
        },
        options: {
          retry: false,
        }
    });
    const posts = postsResponse.data?.posts || [];

    return (
        <ScreenLayout preloader={postsResponse.isLoading}>
            <NavigationBar />

            <Header pageTitle="Biyomimetik Merkezi" />

            <div className="page-content">
                <ImageGallery items={posts.map((post) => ({ src: post.feature_image }))} />

                <Divider hideDivider />

                <ProjectInfo />

                <Divider />

                <GalleryContent
                    title="Biyomimetik Proje Okulu"
                    description="Projenin Uygulanacağı Çatalca Elbasan İlkokulu'nun Dönüşümü"
                    images={projectSchoolImages}
                />

                <Divider />

                <GalleryContent
                    title="Biyomimetik Inovasyon Fuarı"
                    description="Biyomimetik Inovasyon Fuarından Kareler"
                    images={innovationFairImages}
                />

                <Divider />

                <GalleryContent
                    title="Rami Kütüphanesi"
                    description="Biyomimetik Rami Kütüphanesi"
                    images={ramiLibraryImages}
                />

                <Divider />

                <ProjectPartners />
            </div>

            <LogEvent eventName="homepage_visit" />
        </ScreenLayout>
    )
}

export default HomeScreen;