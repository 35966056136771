import { CSSProperties, Fragment } from "react";
import { Link } from "react-router-dom";

type NavItem = {
  id: number;
  name: string;
  href: string;
  icon: string;
  disabled?: boolean;
}

function NavigationBar() {
  const activeNav = window.location.pathname;

  const disabledButton = (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault();
  const disabledStyle: CSSProperties = {
    opacity: 0.5,
    cursor: 'default',
  };

  const navItems: Array<NavItem> = [
    {
      id: 1,
      name: 'Ana Sayfa',
      href: '/',
      icon: 'fa fa-home',
    },
    {
      id: 2,
      name: 'Blog',
      href: '/blog',
      icon: 'fa fa-file',
    },
    {
      id: 3,
      name: 'Etkinlikler',
      href: '/announcements',
      icon: 'fa fa-institution',
    },
    {
      id: 4,
      name: 'Konumlar',
      href: '/locations',
      icon: 'fa  fa-layer-group',
      disabled: true,
    },
  ];

  const renderActiveNav = ({ name, href, icon }: NavItem) => (
    <Link to={href} className="circle-nav active-nav">
      <em></em>
      <strong><u></u></strong>
      <i className={icon}></i>
      <span>{name}</span>
    </Link>
  );

  const renderNav = ({ name, href, icon, disabled }: NavItem) => (
    <Link
      to={href}
      onClick={(e) => disabled ? disabledButton(e) : undefined}
      style={disabled ? disabledStyle : {}}
    >
      <i className={icon}></i>
      <span>{name}</span>
    </Link>
  );

  return (
    <div id="footer-bar" className="footer-bar-6">
      {navItems.map((navItem) => {
        if (activeNav === navItem.href) return (
            <Fragment key={navItem.id}>
              {renderActiveNav(navItem)}
            </Fragment>
          );

        return (
          <Fragment key={navItem.id}>
            {renderNav(navItem)}
          </Fragment>
        )
      })}
    </div>
  );
}

export default NavigationBar;