import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Providers
import AppkitCustomJSProvider from "../../providers/AppkitCustomJSProvider";

type Props = {
  children: React.ReactNode;
  preloader?: boolean | 'auto';
}

function ScreenLayout({
  children,
  preloader = 'auto',
}: Props) {
  const location = useLocation();

  useEffect(() => {
    const stopPreloader = () => {
      const preloader = document.getElementById('preloader')

      if (preloader) {
        preloader.classList.add('preloader-hide');
      }
    }

    // Handle Preloader
    if (typeof preloader === 'string' && preloader === 'auto') {
      setTimeout(stopPreloader, 150);
    } else if (typeof preloader === 'boolean') {
      if (!preloader) stopPreloader();
    }
  }, [location, preloader])

  return (
    <AppkitCustomJSProvider>
      <div id="preloader">
        <div className="spinner-border color-highlight" role="status" />
      </div>

      <div id="page">
        {children}
      </div>

      <div
        id="menu-main"
        className="menu menu-box-left rounded-0"
        data-menu-load="dev/menu-main.html"
        data-menu-width="280"
        data-menu-active="nav-pages"
      >
        Menu
      </div>

      <div
        id="menu-share"
        className="menu menu-box-bottom rounded-m"
        data-menu-load="dev/menu-share.html"
        data-menu-height="370"
      ></div>

      <div
        id="menu-colors"
        className="menu menu-box-bottom rounded-m"
        data-menu-load="dev/menu-colors.html"
        data-menu-height="480"
      ></div>
    </AppkitCustomJSProvider>
  );
}

export default ScreenLayout;