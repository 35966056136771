import { useState } from "react";
import { logEvent } from "firebase/analytics";

import NavigationBar from "../../components/layout/NavigationBar";
import ScreenLayout from "../../components/layout/ScreenLayout";
import ContentMenu from "../../components/content-menu";
import LogEvent from "../../components/async/log-event";

import AnnouncementCard from "./components/AnnouncementCard";

import { usePosts } from "../../hooks/use-posts";

import type { Post } from "../../types/posts";

import { analytics } from "../../firebase";

function AnnouncementsScreen() {
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState<Post | null>(null);

  const postsResponse = usePosts({
    params: {
      include: 'authors,tags',
      filter: 'tag:duyuru',
    },
    options: {
      staleTime: 30000,
      refetchOnWindowFocus: false,
    }
  });
  const posts = postsResponse.data?.posts || [];

  const handleOpenMenu = (announcement: Post) => {
    setIsMenuActive(true);
    setCurrentAnnouncement(announcement);

    logEvent(analytics, 'view_announcement_content', {
      announcement_title: announcement.title,
      announcement_slug: announcement.slug,
      announcement_id: announcement.id,
    });
  };

  const handleCloseMenu = () => {
    setIsMenuActive(false);
    setCurrentAnnouncement(null);
  }

  return (
    <ScreenLayout preloader={postsResponse.isLoading}>
        <NavigationBar />
        <div className="page-content pb-3">

            <div className="card card-full rounded-m pb-4">
                <div className="content mb-0">
                    <h1 className="font-26 font-800 mb-3">Duyurular</h1>

                    {posts.map((item) => (
                        <AnnouncementCard
                          title={item.title}
                          subTitle="Biyomimetik Duyuru"
                          image={item.feature_image}
                          key={item.id}
                          onClick={() => handleOpenMenu(item)}
                        />
                    ))}
                </div>

                <div className="divider divider-margins mb-4"></div>

            </div>


        </div>

        <ContentMenu
          title={currentAnnouncement?.title || ''}
          description="Add your touch, your style, your ideas"
          htmlContent={currentAnnouncement?.html || ''}
          image={currentAnnouncement?.feature_image}
          isActive={isMenuActive}
          handleCloseMenu={handleCloseMenu}
        />

      <LogEvent eventName="announcements_page_visit" />
    </ScreenLayout>
  );
}

export default AnnouncementsScreen;