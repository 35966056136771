import { useQuery, QueryObserverOptions } from '@tanstack/react-query';
import { PostResponse } from '../types/posts';
import { getPosts } from '../api/posts';

type Param = {
  params?: {
    [key: string]: string | number;
  },
  options?: QueryObserverOptions<PostResponse>,
};

export function usePosts(param: Param = { params: {}, options: {} }) {
  const { params, options } = param;
  
  const postsQuery = useQuery({
      queryKey: ['posts', params],
      queryFn: () => getPosts(params),
      ...options,
  });

  return {
    posts: postsQuery.data,
    ...postsQuery,
  };
}