import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Screens
import HomeScreen from "./modules/home/Home.Screen";
import BlogScreen from "./modules/blog/Blog.Screen";
import AnnouncementsScreen from "./modules/announcements/Announcements.Screen";
import LocationsScreen from "./modules/locations/Locations.Screen";
import PrivacyScreen from "./modules/privacy/Privacy.Screen";
import Error404Screen from "./modules/error/error404/Error404.Screen";

const router = createBrowserRouter([
  {
    path: '*',
    element: <Error404Screen />,
  },
  {
    path: '/',
    element: <HomeScreen />,
  },
  {
    path: '/blog',
    element: <BlogScreen />,
  },
  {
    path: '/announcements',
    element: <AnnouncementsScreen />,
  },
  {
    path: '/locations',
    element: <LocationsScreen />,
  },
  {
    path: '/privacy',
    element: <PrivacyScreen />,
  },
]);

function App() {
    return (
      <RouterProvider router={router} />
    );
}
  
export default App;
