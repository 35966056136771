type Props = {
    hideDivider?: boolean;
}

function DividerMargin({ hideDivider }: Props) {
    return (
        <div className={`${!hideDivider && 'divider'} divider-margins`}></div>
    )
}

export default DividerMargin;