import { Link } from "react-router-dom";
import ScreenLayout from "../../../components/layout/ScreenLayout";
import NavigationBar from "../../../components/layout/NavigationBar";

function Error404Screen() {
  return (
    <ScreenLayout>
      <div className="header header-fixed header-logo-center header-auto-show">
            <a href="index.html" className="header-title">404</a>
            <a href="#" data-back-button className="header-icon header-icon-1"><i className="fas fa-chevron-left"></i></a>
            <a href="#" data-menu="menu-main" className="header-icon header-icon-4"><i className="fas fa-bars"></i></a>
            <a href="#" data-toggle-theme className="header-icon header-icon-3 show-on-theme-dark"><i
                    className="fas fa-sun"></i></a>
            <a href="#" data-toggle-theme className="header-icon header-icon-3 show-on-theme-light"><i
                    className="fas fa-moon"></i></a>
        </div>

        <NavigationBar />

        <div className="page-title page-title-fixed">
            <h1>404</h1>
            <a href="#" className="page-title-icon shadow-xl bg-theme color-theme" data-menu="menu-share"><i
                    className="fa fa-share-alt"></i></a>
            <a href="#" className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light" data-toggle-theme><i
                    className="fa fa-moon"></i></a>
            <a href="#" className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark" data-toggle-theme><i
                    className="fa fa-lightbulb color-yellow-dark"></i></a>
            <a href="#" className="page-title-icon shadow-xl bg-theme color-theme" data-menu="menu-main"><i
                    className="fa fa-bars"></i></a>
        </div>
        <div className="page-title-clear"></div>

        <div className="page-content">


            <div className="card card-style p-4 text-center py-5">
                <p className="font-600 color-highlight mb-0">Sayfa Bulunamadı</p>
                <h1>Wooops! Burada hiç bir şey bulunamadı!</h1>
                <p className="pb-4">
                    Üzgünüz, aradığınız sayfa bulunamıyor.
                </p>
                <Link to="/" data-back-button className="btn btn-l btn-center-m font-700 gradient-highlight rounded-s mb-3">Ana Sayfaya Dön</Link>
            </div>

            <div data-menu-load="menu-footer.html"></div>
        </div>

        <div id="menu-main" className="menu menu-box-left rounded-0" data-menu-load="menu-main.html" data-menu-width="280"
            data-menu-active="nav-pages"></div>

        <div id="menu-share" className="menu menu-box-bottom rounded-m" data-menu-load="menu-share.html"
            data-menu-height="370"></div>

        <div id="menu-colors" className="menu menu-box-bottom rounded-m" data-menu-load="menu-colors.html"
            data-menu-height="480"></div>
    </ScreenLayout>
  );
}

export default Error404Screen;