import axios from 'axios';
import { PostResponse } from '../types/posts';

const BASE_URL = `${process.env.REACT_APP_CONTENT_API_URL}/posts`;
const KEY = process.env.REACT_APP_CONTENT_KEY;

export const getPosts = (params?: { [key: string]: string | number }): Promise<PostResponse> => {
  const path = BASE_URL;

  const config = {
    params: {
      key: KEY,
      ...params,
    },
  };

  return axios.get(path, config).then(response => response.data);
};