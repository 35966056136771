import './style.css';

type Props = {
    isActive: boolean;
    handleCloseMenu: () => void;
    title: string;
    description?: string;
    htmlContent: string;
    image?: string;
}

function ContentMenu({
    isActive = false,
    handleCloseMenu,
    title = "",
    description,
    htmlContent,
    image,
}: Props) {
    return (
      <div className={`menu menu-box-right rounded-0 blog-content-menu ${isActive && 'menu-active'}`} data-menu-width="cover">
          <div className="card rounded-0" data-card-height="450">
              <div className="card-bottom ps-3 pb-4">
                  <h1 className="color-white font-24 mb-n1">
                      {title}
                  </h1>
                  {description && (
                      <p className="color-white font-14 opacity-50">
                          {description}
                      </p>
                  )}
              </div>
              <div className="card-top mt-3 pb-5 ps-3">
                  <a
                    href="#"
                    className="close-menu icon icon-s bg-theme rounded-xl float-start me-3"
                    onClick={handleCloseMenu}
                  >
                    <i className="fa color-theme fa-arrow-left"></i>
                  </a>
              </div>
              <div className="card-overlay bg-gradient"></div>
              <img
                src={image || 'images/blog/blog-card-default.jpg'}
                alt="image"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: image ? 'cover' : 'contain',
                }}
              />
          </div>
  
          <SocialMediaArea />
  
          <div className="card card-style">
              <div className="content reading-progress-text">
                  <div className="row">
                      <div className="col-6 pt-1">
                          <p id="reading-progress-text">
                            <strong className="reading-progress-time color-highlight"></strong> dakika -
                            <strong className="reading-progress-words color-highlight"></strong> kelime.
                          </p>
                      </div>
                  </div>
                  <div className="divider divider-margins mx-0"></div>
                  <div dangerouslySetInnerHTML={{__html: htmlContent}} className="pb-5" />
              </div>
          </div>
  
          <a
              href="#"
              className="close-menu btn btn-l rounded-sm font-600 btn-full btn-margins gradient-red"
              onClick={handleCloseMenu}
          >
            Kapat
          </a>
      </div>
    );
}
  
function SocialMediaArea() {
    return (
        <div className="card card-style" style={{ marginTop: '-60px', zIndex: 1 }}>
            <div className="d-flex justify-content-between">
                <div>
                    <a href="#" className="shareToFacebook icon-xl text-center">
                        <i className="fab fa-facebook-f font-14 color-facebook"></i>
                    </a>
                </div>
                <div>
                    <a href="#" className="shareToTwitter icon-xl text-center">
                        <i className="fab fa-twitter font-14 color-twitter"></i>
                    </a>
                </div>
                <div>
                    <a href="#" className="shareToWhatsApp icon-xl text-center">
                        <i className="fab fa-whatsapp font-14 color-whatsapp"></i>
                    </a>
                </div>
                <div>
                    <a href="#" className="shareToLinkedIn icon-xl text-center">
                        <i className="fab fa-linkedin-in font-14 color-linkedin"></i>
                    </a>
                </div>
                <div>
                    <a href="#" className="shareToMail icon-xl text-center">
                        <i className="fa fa-envelope font-14 color-mail"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}
  
export default ContentMenu;