import { useEffect, useState } from "react";

type Props = {
  pageTitle?: string;
};

type Theme = 'light' | 'dark';

function Header({ pageTitle }: Props) {
  const themeLSKey = 'app-theme-mode';
  const themeLSValue: Theme = window.localStorage.getItem(themeLSKey) as Theme;
  const defaultTheme: Theme = 'light';

  const [theme, setTheme] = useState<Theme>(themeLSValue || defaultTheme);

  const switchTheme = (theme: 'light' | 'dark') => {
    window.localStorage.setItem(themeLSKey, theme);
    setTheme(theme);
  }

  useEffect(() => {
    const body = document.body;
    body.className = `theme-${theme}`;
  }, [theme]);

  return (
    <>
      <div className="page-title page-title-fixed">
        <h1>{pageTitle}</h1>

        <a
          href="#"
          className="page-title-icon shadow-xl bg-theme color-theme"
          data-menu="menu-share"
        >
          <i className="fa fa-share-alt"></i>
        </a>

        
        <a
          href="#"
          onClick={() => switchTheme('dark')}
          className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-light"
          data-toggle-theme
        >
          <i className="fa fa-moon"></i>
        </a>

        <a
          href="#"
          onClick={() => switchTheme('light')}
          className="page-title-icon shadow-xl bg-theme color-theme show-on-theme-dark"
          data-toggle-theme
        >
          <i className="fa fa-lightbulb color-yellow-dark"></i>
        </a>

        {/* <a
          href="#"
          className="page-title-icon shadow-xl bg-theme color-theme"
          data-menu="menu-main"
        >
          <i className="fa fa-bars"></i>
        </a> */}
      </div>
      <div className="page-title-clear"></div>
    </>
  );
}

export default Header;