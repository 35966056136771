import { formatData } from "../../../utils/format-data";

type Props = {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  title: string;
  image?: string;
  categories?: Array<{
    title: string;
    id: string;
  }>;
  createdAt: string;
}

function BlogCard({
  onClick,
  title,
  image,
  categories,
  createdAt,
}: Props) {
  return (
    <a
      href="#"
      className="card card-style mx-3 rounded-s"
      data-card-height="250"
      style={{ height: '250px' }}
      onClick={onClick}
    >
      <div className="card-top">
        {categories && categories?.map((category) => (
          <span
            key={category.id}
            className="badge bg-blue-dark px-3 py-2 mt-3 ms-3 text-uppercase rounded-s"
          >
            {category.title}
          </span>
        ))}
      </div>
      <div className="card-bottom mb-4 pb-1 mx-3">
          <h1 className="color-white font-20 font-700">{title}</h1>
      </div>
      <div className="card-bottom">
        <p className="color-white opacity-50 mb-2"><i className="fa fa-user px-3"></i>Biyomimetik</p>
      </div>
      <div className="card-bottom">
          <p className="color-white opacity-50 mb-2 text-end">
            {formatData(createdAt)}
            <i className="fa fa-clock px-3"></i>
          </p>
      </div>
      <img
        src={image || 'images/blog/blog-card-default.jpg'}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <div className="card-overlay bg-gradient-overlay rounded-s"></div>
    </a>
  );
}

export default BlogCard;