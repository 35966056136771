function ProjectPartners() {
    return (
        <div className="card card-style">
            <div className="content">
                <h1>
                    Proje Ortaklarımız
                </h1>
                <div className="row row-cols-4 px-1 mb-0">
                    <a className="col p-2" href="/images/pictures/sanayi-bakanligi-150x150-2.png" data-gallery="gallery-a">
                    <img src="/images/pictures/sanayi-bakanligi-150x150-2.png" alt="img"
                        className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/istanbul-kalkinma-150x150-2.png" data-gallery="gallery-a">
                    <img src="/images/pictures/istanbul-kalkinma-150x150-2.png" alt="img"
                        className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/vali-sitanbul-150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/vali-sitanbul-150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/ist-tic-uni-150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/ist-tic-uni-150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/catalca-bld-150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/catalca-bld-150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/bld-150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/bld-150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/btm-150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/btm-150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                    <a className="col p-2" href="/images/pictures/dusuntasin-logo150x150.png" data-gallery="gallery-a">
                    <img src="/images/pictures/dusuntasin-logo150x150.png" alt="img" className="img-fluid rounded-s shadow-xl" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ProjectPartners;