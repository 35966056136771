import LogEvent from "../../components/async/log-event";
import Header from "../../components/layout/Header";
import NavigationBar from "../../components/layout/NavigationBar";
import ScreenLayout from "../../components/layout/ScreenLayout";

import Content from "./components/Content";

function PrivacyScreen() {
  return (
    <ScreenLayout>
        <NavigationBar />

        <Header pageTitle="Gizlilik Politikası" />

        <div className="page-content">
            <div className="px-5">
                <Content />
            </div>
        </div>

        <LogEvent eventName="privacy_page_visit" />
    </ScreenLayout>
  );
}

export default PrivacyScreen;
