import { useMemo } from 'react';
import ReactImageGallery, { type ReactImageGalleryItem } from 'react-image-gallery';

type Props = {
  autoPlay?: boolean;
  showBullets?: boolean,
  items: Array<{
    src: string;
  }>;
};

function ImageGallery({
  autoPlay = true,
  showBullets = false,
  items,
}: Props) {
  const images = useMemo(
    // ref: https://github.com/xiaolin/react-image-gallery?tab=readme-ov-file#react-image-gallery
    () => items.map((item) => ({
      original: item.src
    }) as ReactImageGalleryItem),
    [items],
  );

  return (
    <ReactImageGallery
      items={images}
      autoPlay={autoPlay}
      infinite
      showBullets={showBullets}
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={false}
    />
  );
}

export default ImageGallery;