// doc: https://firebase.google.com/docs/analytics/events?platform=web

import { useEffect } from "react";
import { logEvent } from "firebase/analytics";

import { analytics } from "../../../firebase";

type Props = {
  /**
   * The title of the user's event.
   * You need to include this title to understand which event the user triggered.
  */
  eventName: string;
  eventParam?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }
};

/**
 * This component detects the page navigation activity of the user on
 * the page it is on and sends it as a log to firebase analytics.
 * If you want to know if a user has entered a page, add this component
 * anywhere in the jsx content of the page.
 * 
 * EXAMPLE:
 * ```tsx
 * function MyComponent() {
 *  return (
 *    <>
 *      // content...
 *      <LogEvent eventName="my_page" />
 *    </>
 *  );
 * }
 * ```
*/
function LogEvent({ eventName, eventParam }: Props) {
  useEffect(() => {
    logEvent(analytics, eventName, eventParam)
  }, [])

  return null;
}

export default LogEvent;