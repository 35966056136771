import LogEvent from "../../components/async/log-event";
import NavigationBar from "../../components/layout/NavigationBar";
import ScreenLayout from "../../components/layout/ScreenLayout";

function LocationsScreen() {
  return (
    <ScreenLayout>
        <NavigationBar />

        <div className="card card-fixed mb-n5" data-card-height="450">
            <div className="map-full">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3004.794117205796!2d28.40975667649794!3d41.13902021136353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b54521bf9d9ded%3A0xe8f55b1597f53cd3!2sBiyomimetik%20Merkezi!5e0!3m2!1str!2str!4v1703083956448!5m2!1str!2str" width="100%" height="450" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div className="card card-clear" data-card-height="450"></div>

        <div className="page-content pb-3">

            <div className="card card-full rounded-m pb-4">
                <div className="drag-line"></div>

                <div className="content">
                    <p className="font-600 mb-n1 color-highlight">Konumlar</p>
                    <h1>Biyomimetik alanında çalışan enstitu ve doğa tarihi yerleri</h1>
                    <p>
                        Biyomimetik alanında çalışan enstitu ve doğa tarihi yerleri gösterilmiştir.
                    </p>

                    <div className="d-flex">
                        <div className="me-auto">
                            <img src="images/pictures/1s.jpg" width="140" className="rounded-sm" />
                        </div>
                        <div className="w-100 ps-3">
                            <h4 className="mb-0">Konum - 1</h4>
                            <p className="mb-2">Train with legends in the world of bodybuilding</p>
                            <p>
                                <i className="fa fa-star color-yellow-dark icon-10 text-center me-2"></i>5.0
                                <i className="fa fa-map-marker color-blue-dark icon-10 text-center ms-2 me-2"></i>3.4 Miles
                                Away
                                <br />
                                <span className="mt-3 badge border color-green-dark border-green-dark">POPULAR</span>
                            </p>
                        </div>
                    </div>

                    <div className="divider mt-3 mb-3"></div>

                    <div className="d-flex">
                        <div className="me-auto">
                            <img src="images/pictures/4s.jpg" width="140" className="rounded-sm" />
                        </div>
                        <div className="w-100 ps-3">
                            <h4 className="mb-0">Konum - 2</h4>
                            <p className="mb-2">Have a cold one and relax after a long day</p>
                            <p>
                                <i className="fa fa-star color-yellow-dark icon-10 text-center me-2"></i>4.1
                                <i className="fa fa-map-marker color-blue-dark icon-10 text-center ms-2 me-2"></i>4.6 Miles
                                Away
                                <br />
                                <span className="mt-2 badge border color-red-dark border-red-dark">PREMIUM</span>
                                <span className="ms-2 badge border color-blue-dark border-blue-dark">FREE WIFI</span>
                            </p>
                        </div>
                    </div>


                    <div className="divider mt-3 mb-3"></div>

                    <div className="d-flex">
                        <div className="me-auto">
                            <img src="images/pictures/6s.jpg" width="140" className="rounded-sm" />
                        </div>
                        <div className="w-100 ps-3">
                            <h4 className="mb-0">Konum - 3</h4>
                            <p className="mb-2">Get grooving to the sounds of your favorite tunes</p>
                            <p>
                                <i className="fa fa-star color-yellow-dark icon-10 text-center me-2"></i>4.8
                                <i className="fa fa-map-marker color-blue-dark icon-10 text-center ms-2 me-2"></i>7.3 Miles
                                Away
                                <br />
                                <span className="mt-3 badge border color-green-dark border-green-dark">POPULAR</span>
                                <span className="ms-2 badge border color-red-dark border-red-dark">PREMIUM</span>
                            </p>
                        </div>
                    </div>

                    <div className="divider mt-3 mb-3"></div>

                    <div className="d-flex">
                        <div className="me-auto">
                            <img src="images/pictures/16s.jpg" width="140" className="rounded-sm" />
                        </div>
                        <div className="w-100 ps-3">
                            <h4 className="mb-0">Konum - 4</h4>
                            <p className="mb-2">Think you can handle it? Join us and show us!</p>
                            <p>
                                <i className="fa fa-star color-yellow-dark icon-10 text-center me-2"></i>4.3
                                <i className="fa fa-map-marker color-blue-dark icon-10 text-center ms-2 me-2"></i>9.8 Miles
                                Away
                                <br />
                                <span className="mt-2 badge border color-red-dark border-red-dark">PREMIUM</span>
                                <span className="ms-2 badge border color-blue-dark border-blue-dark">FREE WIFI</span>
                            </p>
                        </div>
                    </div>


                    <div className="divider mt-3 mb-3"></div>

                    <div className="d-flex">
                        <div className="me-auto">
                            <img src="images/pictures/19s.jpg" width="140" className="rounded-sm" />
                        </div>
                        <div className="w-100 ps-3">
                            <h4 className="mb-0">Konum - 5</h4>
                            <p className="mb-2">Grab a coffee and stay on your phone, you're safe!</p>
                            <p>
                                <i className="fa fa-star color-yellow-dark icon-10 text-center me-2"></i>4.5
                                <i className="fa fa-map-marker color-blue-dark icon-10 text-center ms-2 me-2"></i>11.5 Miles
                                Away
                                <br />
                                <span className="mt-3 badge border color-green-dark border-green-dark">POPULAR</span>
                                <span className="ms-2 badge border color-red-dark border-red-dark">PREMIUM</span>
                            </p>
                        </div>
                    </div>

                    <a href="#" data-back-to-top
                        className="btn gradient-blue btn-full btn-m font-700 font-12 mt-4 rounded-s">Back to Map</a>

                </div>
            </div>
        </div>

        <div id="menu-main" className="menu menu-box-left rounded-0" data-menu-load="dev/menu-main.html" data-menu-width="280"
            data-menu-active="nav-pages"></div>

        <div id="menu-share" className="menu menu-box-bottom rounded-m" data-menu-load="dev/menu-share.html"
            data-menu-height="370"></div>

        <div id="menu-colors" className="menu menu-box-bottom rounded-m" data-menu-load="dev/menu-colors.html"
            data-menu-height="480"></div>

    <LogEvent eventName="locations_page_visit" />
    </ScreenLayout>
  );
}

export default LocationsScreen;