function ProjectInfo() {
    return (
        <div className="card card-style">
            <div className="content">
                <p className="mb-n1 color-highlight font-600">BİYOMİMETİK MERKEZİ PROJESİ NEDİR? </p>
                <h1>
                    İstanbul Doğadan İlham Alan İnovasyon Merkezi </h1>
                <p>
                    İstanbul Kalkınma Ajansının 2021 mali destek Programı kapsamında çocuklar ve gençler mali destek programına
                    başvuru yapıp başarılı bulunan BİYOMİMETİK Merkezi projemiz; Ortaklarımız İstanbul Valiliği, İstanbul
                    Ticaret Üniversitesi, Bilgiyi Ticarileştirme Merkezi, Çatalca Belediyesi ve Çatalca İlçe Milli Eğitim
                    Müdürlüğü’nün destekleri ile hayata geçirilecektir.

                    Projede çocuklar ve gençlerin hayatlarına doğa, bilim ve biyomimetik kavramlarını taşıyıp onların
                    hayatlarında unutulmaz bir katma değer sağlamak istiyoruz.
                </p>

                <img
                  src="/images/pictures/Mansetler-3-1.jpg"
                  alt="mansetler"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '15px',
                  }}
                />
            </div>
        </div>
    );
}

export default ProjectInfo;