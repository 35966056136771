// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpCyk-sFFWkRasYP3pIeyYAnd0idqnJVE",
  authDomain: "biyomimetric.firebaseapp.com",
  projectId: "biyomimetric",
  storageBucket: "biyomimetric.appspot.com",
  messagingSenderId: "992217055961",
  appId: "1:992217055961:web:afddaab0067726a15974c8",
  measurementId: "G-RXKD2MJTHR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
