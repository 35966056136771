import { useMemo } from "react";
import ImageGallery from "../../../components/image-gallery";

export default function GalleryContent({
    title,
    description,
    images,
}: {
    title?: string,
    description?: string,
    images: string[],
}) {
    const imgItems = useMemo(() => images.map((image) => ({ src: image })), []);

    return (
        <div className="card card-style">
            <div className="content">
                <h4>{title}</h4>
                <p>{description}</p>

                <ImageGallery showBullets items={imgItems} />
            </div>
        </div>
    );
}
